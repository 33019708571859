<template>
  <div>
    <StandardDialog
      v-model="localOpenDialog"
      persistent
      :title="title"
      dialog-width="60vw"
      dialog-height="calc(30vh-1)"
    >
      <v-row class="ma-10">
        <v-col cols="5">
          <BorderedDiv description="Codice Interno Prodotto">
            <template v-slot:content="{}">
              <v-icon>mdi-code-tags</v-icon>
              <h3>{{ internalCode }}</h3>
            </template>
          </BorderedDiv>
          <BorderedDiv description="Barcode">
            <template v-slot:content="{}">
              <v-icon>mdi-barcode</v-icon>
              <h3>{{ barcode }}</h3>
            </template>
          </BorderedDiv>
          <BorderedDiv description="Fornitore">
            <template v-slot:content="{}">
              <v-icon>mdi-account-multiple</v-icon>
              <h3>{{ supplierBusinessName }}</h3>
            </template>
          </BorderedDiv>
        </v-col>

        <v-col cols="7">
          <ListItemComponent
            :rounded="true"
            :flat="true"
            title="Lista codici associati"
            height="35vh"
            min-height="35vh"
            max-height="35vh"
            color="white"
            :show-header-actions="true"
          >
            <template v-slot:header-actions="{}">
              <v-tooltip allow-overflow bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    fab
                    small
                    @click="handleAddSubCode"
                    icon
                  >
                    <v-icon>mdi-qrcode-plus</v-icon>
                  </v-btn>
                </template>
                <span>Aggiungi Codice</span>
              </v-tooltip>
            </template>
            <template class="border-default" v-slot:list-item="{}">
              <v-list-item-subtitle v-for="(item, y) in subCodes" :key="y">
                <v-list-item-subtitle
                  style="font-size: 17px; font-weight: bold"
                  class="d-flex flex-row mb-2"
                >
                  <v-row>
                    <v-col class="mt-2">
                      <div class="d-flex justify-start">
                        <v-tooltip allow-overflow bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              small
                              @click="
                                setDefaultValueSubCode(item, !item.default)
                              "
                              icon
                              fab
                              ><v-icon :color="item.default ? 'yellow' : ''"
                                >mdi-star</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Imposta come codice padre</span>
                        </v-tooltip>
                        <v-tooltip allow-overflow top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              v-on="on"
                              v-bind="attrs"
                              @click="deleteSubCode(item)"
                              icon
                              fab
                              ><v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </template>
                          <span>Elimina</span>
                        </v-tooltip>

                        <div class="mt-2 d-flex justify-start">
                          {{ item.code }}
                        </div>
                      </div>
                      <VueBarcode
                        class="d-flex justify-start"
                        v-model="item.code"
                        height="35px"
                        :display-value="false"
                      ></VueBarcode>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-divider></v-divider>
              </v-list-item-subtitle>
            </template>
          </ListItemComponent>
        </v-col>
      </v-row>

      <StandardDialog
        dialog-width="45vw"
        dialog-height="calc(180-1)"
        v-model="openSubCodeDialog"
        title="Associa nuovo codice"
      >
        <div class="d-flex flex-column justify-center align-center">
          <div class="text-h3">{{ newSubCode }}</div>
          <VueBarcode
            v-model="newSubCode"
            height="35px"
            :display-value="false"
          ></VueBarcode>
          <v-text-field
            v-model="newSubCode"
            filled
            class="mt-2 mb-1"
            :rules="[presenceRule]"
            label="Nuovo codice"
            dense
          ></v-text-field>
          <v-alert v-if="barcodeAlreadyPresentAlert" type="error" outlined>
            {{ errorMessage }}
          </v-alert>
        </div>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn text @click="associateSubCode">Associa</v-btn>
        </template>
      </StandardDialog>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text class="border-default" color="error" @click="$emit('close')">Chiudi</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import BorderedDiv from "@/components/common/BorderedDivComponent.vue";

import ListItemComponent from "@/components/common/ListItemComponent.vue";
import barcodeService from "@/services/barcode/barcode.service.js";
import itemService from "@/services/warehouse/items.service.js";
import StandardDialog from "@/components/common/StandardDialog";
import VueBarcode from "vue-barcode";

export default {
  components: {
    BorderedDiv,
    StandardDialog,
    ListItemComponent,
    VueBarcode,
  },
  props: {
    itemId: {
      type: Number,
      default: 0,
    },
    orderId: {
      type: Number,
      default: 0,
    },
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "Riepilogo codici associati",
      localOpenDialog: this.openDialog,
      openSubCodeDialog: false,
      newSubCode: "",
      barcodeAlreadyPresentAlert: false,
      errorMessage: "",
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      barcode: undefined,
      subCodes: [],
      currentBarcode: undefined,
      internalCode: undefined,
      supplierBusinessName: undefined,
    };
  },
  mounted() {
    if (!!this.itemId) this.getItem(this.itemId);
  },
  methods: {
    setLocalValue(item) {
      if (!!item) {
        this.subCodes = item.barcodes.filter((item) => !item.default).reverse();
        this.currentBarcode = item.barcodes.filter((item) => item.default)[0];
        this.internalCode = item.internalCode;
        this.description = item.description;
        this.supplierBusinessName = item.supplier.businessName;
        this.barcode = item.code ? item.code : "";
      }
    },
    getItem(id) {
      itemService
        .get(id)
        .then((item) => {
          this.setLocalValue(item);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteSubCode(item) {
      if (item.code === this.code)
        alert("Impossibile eliminare il codice padre del prodotto");
      else {
        let confirmed = confirm(
          "Sei sicuro di voler eliminare il sotto codice " + item.code + " ?"
        );

        if (confirmed) {
          barcodeService.delete("items", { id: item.id }).then((value) => {
            this.newSubCode = "";
            this.openSubCodeDialog = false;
            this.getItem(this.itemId);
          });
        }
      }
    },
    associateSubCode() {
      if (!!this.newSubCode && !!this.itemId)
        barcodeService
          .create(this.newSubCode, "items", { id: this.itemId }, false)
          .then((value) => {
            this.newSubCode = "";
            this.openSubCodeDialog = false;
            this.getItem(this.itemId);
          })
          .catch((err) => {
            if (err == "Code Already Present")
              err = "Codice a Barre Già Inserito";
            this.errorMessage = err;
            this.barcodeAlreadyPresentAlert = true;
          });
    },
    handleAddSubCode() {
      this.barcodeAlreadyPresentAlert = false;
      this.newSubCode = this.barcodeScanned;
      this.openSubCodeDialog = true;
    },
    setDefaultValueSubCode(item, defaultValue) {
      let barcodeUpdate = {
        id: item.id,
        default: defaultValue,
        currentBarcode: this.currentBarcode,
      };
      barcodeService
        .updateDefault("items", barcodeUpdate)
        .then(() => {
          let itemUpdate = { id: this.itemId, code: item.code };

          itemService
            .update({ item: itemUpdate, barcode: item.code })
            .then((value) => {
              this.$emit("changed-code", item.code);
              this.getItem(this.itemId);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>